.block-sharethis {
  & .sharethis-wrapper {
    & span {
      @extend .icon;
      vertical-align: middle;

      &::before {
        font-size: 2.4rem;
        margin-top: 6px;
        margin-left: 2px;
        line-height: 1;
        width: 24px;
        height: 24px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
      }
    }

    & .st_facebook_custom {
      @extend .icon-facebook;
      color: #3B5998;
    }

    & .st_twitter_custom {
      @extend .icon-twitter;
      color: #55ACEE;
    }

    & .st_googleplus_custom {
      @extend .icon-google-plus;
      color: #DB4437;
    }
  }
}
