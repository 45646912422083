.site-footer {
  border-top: 48px solid transparent;
  background: var(--greyLight);
  padding-bottom: 36px;

  @media (--screen-md) {
    border-top-width: 72px;
    padding-bottom: 164px;
  }
}
