.region-footer-right {
  @extend .layer-absolute-md;
  right: var(--gutter-fluid);
  font-size: 1.6rem;
  line-height: 2;
  clear: left;
  text-align: center;

  @media (--screen-sm) {
    text-align: left;
  }

  @media (--screen-md) {
    right: var(--gutter-fluid-md);
  }

  @media (--screen-lg) {
    right: var(--gutter-fluid-lg);
  }

  & .block-sitewide-social-block {
    & .block-title {
      @extend .hidden;
    }
  }

  & .block-sitewide-social-block {
    transform: translateY(-120px);

    @media (--screen-sm) {
      margin-top: 12px;
      transform: translateY(0);
    }
  }

  & .block-views-sponsors-footer-block {
    margin-top: 3em;
    transform: translateY(60px);

    @media (--screen-sm) {
      position: absolute;
      transform: translateY(0);
      right: var(--gutter-fluid);
      margin-top: 2px;
    }

    @media (--screen-md) {
      position: absolute;
      right: auto;
      bottom: 40px;
      margin-top: 0;
    }
  }

  & .block-menu-menu-footer {
    @extend .layer-absolute;
    @extend .layer-above;
    right: var(--gutter-fluid);
    bottom: -62px;

    @media (--screen-md) {
      right: auto;
    }

    & a {
      display: inline-block;
    }
  }
}
