.form-item,
.form-actions {
  margin-top: 1em;
  margin-bottom: 2em;
}

.form-type-radio,
.form-type-checkbox {
  margin-top: 0;
  margin-bottom: 0;
}

.fieldset-wrapper {
  & .form-item:first-child {
    margin-top: 0;
  }
}

.form-textarea-wrapper {
  display: inline-block;
  max-width: 420px;
  vertical-align: top;
}

.webform-component-cc-subscribe,
.webform-component-textfield,
.webform-component-email,
.webform-component-number,
.webform-component-checkboxes,
.webform-component-radios,
.webform-component-select,
.webform-component-select-or-other {
  &,
  & label,
  & .form-item {
    margin-bottom: 0;
  }

  & label {
    line-height: 42px;
  }
}

.webform-container-inline {
  & label {
    @extend label;
  }
}

.form-required {
  color: var(--primary);
}

.description {
  font-style: italic;
  font-size: 1.5rem;
  line-height: var(--line-height-secondary);
}

.form-actions {
  background-image: url(../../../theme/img/loader.gif);
  background-repeat: no-repeat;
  background-position: -9999px;
  background-size: 50px 50px;
}

.webform-client-form {
  margin-top: 5em;
  margin-bottom: 10em;
}
