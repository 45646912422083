.view-jobs-list {
  & .views-field-field-wage {
    @extend .view-list .view-list-right;
  }

  & .views-field-nothing {
    & a {
      @extend %btn;
      @extend .link;
    }
  }

  & .views-field-nothing-1 {
    display: none;
    margin-left: 1em;

    & a {
      @extend %btn;
      @extend %btn-secondary;
      @extend %btn-sm;
    }
  }

  & .views-field-field-webtree-deadline {
    display: none;
    margin-bottom: 1em;

    & .field-content {
      display: inline;
    }
  }
}
