.views-exposed-form {
  & .views-exposed-widget {
    float: none;
    padding: 0;
  }

  & label {
    @extend .pull-left;
    font-weight: normal;
    margin-right: 0.5em;
    margin-bottom: 0;
    min-width: 0;
    line-height: 42px;
  }
}
