.site-header {
  top: 0;
  left: 0;
  right: 0;
  line-height: 0;
  background-color: var(--white);
  transform: translateY(0%);
  transition: transform 0.2s;
  text-align: right;
  font-size: 0;
}

.header-logo {
  @extend .layer-absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 2px solid var(--greyLight);
  text-align: left;
}

.header-logo__link {
  @extend .layer-absolute;
  @extend .layer-above;
  display: block;
  width: 151px;
  background-image: url(../../../theme/logo.png);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: translateY(28px);
  transition: transform 0.2s, width 0.2s;

  @media (--screen-xs-only) {
    width: 90px;
    transform: translateY(13px);
  }
}

.header-logo__image {
  @extend .img-fit-x;
  display: block;
  opacity: 0;
}

.header-logo__slogan {
  display: none;
  font-style: italic;
  font-size: 1.1rem;
  line-height: 1.33;
  height: 28px;
  margin-top: 10px;
  opacity: 1;
  transition: height 0.2s, opacity 0.2s;

  @media (--screen-sm) {
    display: block;
    position: relative;
    margin-top: 16px;
  }
}
