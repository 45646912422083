.view-homepage-feature {
  &.view-display-id-block {
    & > .view-content {
      @extend .layer-absolute;
      @extend .layer-above;
      margin: 0 var(--gutter-fluid);
      color: var(--white);
      text-shadow: 1px 1px var(--black);
      max-width: 520px;
      right: 0;
      bottom: 40px;

      @media (--screen-sm) {
        bottom: 134px;
      }

      @media (--screen-md) {
        margin: 0 var(--gutter-fluid-md);
      }

      @media (--screen-lg) {
        margin: 0 var(--gutter-fluid-lg);
      }
    }
  }

  &.view-display-id-attachment {
    & .view-header {
      @extend .layer-absolute;
      @extend .layer-above;
      left: var(--gutter-fluid);

      @media (--screen-md) {
        left: var(--gutter-fluid-md);
      }

      @media (--screen-lg) {
        left: var(--gutter-fluid-lg);
      }
    }

    & .field-content {
      @extend .img-cover;
    }
  }

  & .views-field-field-display-title {
    font-family: var(--font-family-touch);
    font-size: 4.3rem;
    line-height: var(--line-height-secondary);

    @media (--screen-sm) {
      font-size: 5.7rem;
    }

    @media (--screen-lg) {
      font-size: 6.6rem;
    }

    @media (--screen-xl) {
      font-size: 7.7rem;
    }
  }

  & .views-field-field-message {
    line-height: var(--line-height-secondary);
    padding-top: 40px;
  }

  & .views-field-field-webtree-link {
    padding-top: 52px;

    & a {
      @extend %btn;
      @extend %btn-primary;
      text-shadow: none;
    }
  }

  & .sitewide-site-short-name {
    @extend .header-logo__image;
    opacity: 1;
    display: block;
    margin-bottom: -1px;

    & a {
      @extend .header-logo__link;
      position: relative;
      display: block;
      width: 90px;
      height: 44px;
      background-image: url(../../../theme/logo-white-dropshadow.png);
      overflow: hidden;
      text-indent: -9999px;

      @media (--screen-sm) {
        width: 152px;
        height: 74px;
      }
    }
  }

  & .sitewide-site-slogan {
    @extend .header-logo__link;
    @extend .header-logo__slogan;
    display: block;
    width: 160px;
    background: none;
    color: var(--white);
  }
}
