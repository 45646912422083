.view-content-downloads {
  & .views-field {
    display: inline;
  }

  & .views-field-filesize {
    font-style: italic;
    font-size: smaller;
  }
}
