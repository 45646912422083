.block-byline {
  border-top: 2px solid var(--greyLight);
  padding-top: 8px;
  margin-bottom: 5em;
  vertical-align: middle;
  font-size: var(--font-size-secondary);
  line-height: 2;

  & > .block-title {
    @extend .hidden;
  }

  & > .block-content {
    & > .view {
      & > .view-content {
        line-height: var(--line-height-secondary);
        padding-top: 9px;
        padding-bottom: 8px;

        & a {
          @extend .link-inline;
        }
      }
    }
  }

  & .select2-container {
    @extend .pull-left;
  }

  & .views-exposed-widgets {
    margin-bottom: 0;
  }

  & .view-header {
    @extend .pull-right;
    padding-left: var(--gutter);
    height: 42px;
    line-height: 42px;
    text-align: right;
    margin-top: -52px;

    @media (--screen-xs) {
      margin-top: 0;
    }

    & .block-content {
      &, & div {
        display: inline-block;
        vertical-align: middle;
      }
    }

    & .block-title {
      font-family: var(--font-family-content);
      display: inline-block;
      font-size: 1.2rem;
      font-weight: normal;
      line-height: 42px;
      margin-right: 4px;
      vertical-align: top;
    }
  }

  & .view-filters {
    margin-bottom: 5em;
  }

  & .views-field-field-webtree-portrait {
    float: left;

    & img {
      margin-right: 12px;

      @media (--screen-sm) {
        vertical-align: middle;
        margin-top: -6px;
      }
    }
  }

  & .views-field-field-webtree-calendar-date {
    & .field-content {
      @extend .icon;
      @extend .icon-calendar;

      &::before {
        font-size: 2.5rem;
        line-height: 36px;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
  }

  & .views-field-field-webtree-position {
    font-style: italic;
  }
}
