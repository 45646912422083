.node-homepage {
  @extend .layer;
  background-image: url(../../../theme/img/splash-home.jpg);
  background-repeat: no-repeat;
  background-position: left bottom;
  font-size: 4.7rem;
  font-style: italic;

  & .node-content {
    margin-top: 2.5em;
    margin-bottom: 1.5em;

    @media (--screen-md) {
      margin: 0;
    }
  }

  & .content {
    margin-bottom: 0;
  }

  & .page-title {
    @extend .layer-absolute-md;
    @extend .container-fluid;

    margin-top: 0;
    margin-bottom: 0.5em;
    text-align: left;
    font-family: var(--font-family-content);
    font-size: 100%;
    font-weight: normal;
    line-height: var(--line-height-secondary);
    text-transform: none;
    letter-spacing: normal;

    @media (--screen-md) {
      bottom: 55%;
      left: 0;
      width: 50%;
      margin-bottom: 0;
    }
  }

  & .field-name-body {
    @extend .layer-absolute-md;
    @extend .container-fluid;
    font-size: 50%;
    line-height: var(--line-height-secondary);
    margin-bottom: 0;

    @media (--screen-md) {
      top: 52%;
      left: 0;
      width: 50%;
    }
  }

  & .field-name-field-tagline {
    @extend .layer-absolute-md;
    @extend .container-fluid;
    font-size: 75%;
    color: var(--primary);
    margin: 1.5em 0;

    @media (--screen-md) {
      color: var(--white);
      right: 0;
      bottom: 0;
      margin: 1em 0;
      text-shadow: 1px 1px var(--black);
    }
  }

  & .field-name-field-webtree-primary-image {
    @extend .hidden-xs;
    @extend .hidden-sm;
    float: right;
    width: 50%;

    & img {
      @extend .img-fit-x;
    }
  }
}
