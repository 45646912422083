.region-navigation {
  @extend .icon;
  @extend .icon-bars;
  display: inline-block;
  padding-right: 44px;
  padding-left: 44px;
  min-height: 72px;
  transition: min-height 0.2s;
  text-align: left;

  @media (--screen-sm) {
    min-height: 176px;
  }

  @media (--screen-md) {
    margin-right: 22px;
    padding: 0;
  }

  &::before {
    @extend .layer-absolute-md;
    @extend .layer-above;
    font-size: 2.7rem;
    line-height: 72px;
    transform: translateY(0);
    display: inline-block;
    transition: transform 0.2s;

    @media (--screen-sm) {
      transform: translateY(57px);
    }

    @media (--screen-md) {
      content: '' !important;
      top: 178px;
      right: 212px;
      bottom: 0;
      left: 60px;
    }
  }

  & .navigation-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background-color: var(--primary);

    @media (--screen-md) {
      position: static;
      padding: 0;
    }

    & .block {
      padding-right: var(--gutter-fluid);
      padding-left: var(--gutter-fluid);

      @media (--screen-xs-only) {
        /**
         * 72px - nav height
         * 20px - padding top/bottom
         * 70px - search height
         */
        max-height: calc(100vh - 162px);
        overflow-y: auto;
      }

      @media (--screen-sm) {
        max-height: auto;
      }

      @media (--screen-md) {
        padding: 0;
      }
    }
  }

  & .menu-block-wrapper {
    display: none;

    @media (--screen-md) {
      display: block;
    }

    & > .menu {
      & > .menu__item {
        float: none;
        padding: 0;
        margin-top: 0.75em;

        &:first-child {
          margin-top: 0.5em;
        }

        @media (--screen-md) {
          float: left;
          margin-top: 0 !important;
          padding-left: 22px;
          padding-right: 22px;
        }

        & > .menu__link {
          @media (--screen-md) {
            padding-top: 72px;
            padding-bottom: 62px;
            transition: padding 0.2s;
          }

          &::after {
            @media (--screen-md) {
              top: 93px;
              transition: top 0.2s;
            }
          }
        }
      }
    }
  }

  & .menu__item {
    @extend .layer;
    @extend .layer-above;
  }

  & .menu__link {
    display: inline-block;

    @media (--screen-md) {
      line-height: 42px;
    }
  }

  & .menu {
    @extend .layer;
    @extend .layer-above;

    & .menu {
      font-size: 0;
      margin-right: -var(--gutter-fluid);
      margin-left: -var(--gutter-fluid);
      padding-right: var(--gutter-fluid);
      padding-left: var(--gutter-fluid);

      @media (--screen-md) {
        font-size: inherit;
        text-align: right;
        display: none;
        margin: 0;
        margin-top: -42px;
        padding: 0;
        padding-bottom: 72px;
        transition: margin-top 0.2s;
      }

      & .menu__item {
        @extend .layer;
        display: inline-block;
        opacity: 0.8;
        padding-right: 28px;

        @media (--screen-md) {
          display: block;
          height: 42px;
          white-space: nowrap;
        }

        & .menu__link {
          @extend .layer-absolute-md;

          @media (--screen-md) {
            right: 0;
          }
        }
      }
    }
  }

  & .block-search-form {
    @extend .layer-absolute-md;
    @extend .layer-above;
    display: none;

    @media (--screen-md) {
      top: 50%;
      left: var(--gutter-fluid-md);
    }

    @media (--screen-lg) {
      left: var(--gutter-fluid-lg);
    }
  }

  & ~ .region-header {
    & .view-donate-button {
      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 0;
        right: 100%;
        display: block;
        background: transparent;
        transition: width 1s linear;

        @media (--screen-md) {
          display: none;
        }
      }
    }
  }

  &:hover {
    @extend .icon-multiply;
    background-color: var(--primary);

    &::before {
      color: var(--white);
    }

    & .navigation-wrapper {
      padding-top: 10px;
      padding-bottom: 10px;

      @media (--screen-md) {
        padding: 0;
      }
    }

    & .menu-block-wrapper {
      display: block;
    }

    & .menu {
      color: var(--white);

      & .menu {
        display: block;

        @media (--screen-md) {
          display: block;
        }
      }

      & .is-active-trail {
        & .menu {
          display: block;
        }
      }
    }

    @media (--screen-md) {
      padding-left: var(--gutter-fluid-md);

      &::before {
        transform: translateY(-42px);
      }
    }

    @media (--screen-lg) {
      padding-left: var(--gutter-fluid-lg);
    }

    & ~ .header-logo {
      background-color: var(--primary);
      border-bottom: none;

      & .header-logo__link {
        background-image: url(../../../theme/logo-white.png);
      }

      & .header-logo__slogan {
        color: var(--white);
      }
    }

    & ~ .region-header {
      & .view-donate-button {
        &::before {
          width: 84px;
        }

        & .views-field-link-title {
          & a {
            background-color: var(--white);
            color: var(--primary);
          }
        }
      }
    }

    & .block-search-form {
      display: block;
    }
  }
}
