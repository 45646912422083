.view-section-people {
  &.view-display-id-block {
    @extend .clearfix;
    margin-bottom: 5em;

    & .views-row {
      @extend .col-xs-12;
      @extend .col-sm-6;
      @extend .layer;
      @extend .clearfix;
      padding-right: 0;
      padding-left: 0;

      &:hover {
        & .views-field-title {
          & a {
            background-color: var(--secondary);
          }
        }
      }
    }

    & .views-field-title {
      @extend .layer-absolute;
      @extend .layer-above;
      left: 30px;
      right: 30px;
      bottom: 32px;
      text-align: center;
      line-height: var(--line-height-secondary);

      & a {
        display: inline-block;
        padding: 4px 24px;
        background-color: color(var(--white) alpha(90%));
        transition: background-color 0.2s;

        & span {
          display: inline-block;
        }
      }
    }

    & .position {
      font-style: italic;
      font-size: 1.6rem;
      margin-top: 4px;
    }

    & .views-field-field-webtree-portrait {
      padding-top: 100%;

      & .field-content {
        @extend .layer-absolute;
        @extend .layer-below;

        top: 0;
        right: 0;
        left: 0;
      }
    }

    & img {
      @extend .img-fit-x;
    }
  }
}
