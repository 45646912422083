.not-front {
  & .region-content-pre-wrapper {
    margin-top: 72px;

    @media (--screen-sm) {
      margin-top: 92px;
    }

    & + .region-content {
      margin-top: 0;

      @media (--screen-md) {
        margin-top: -15.125%;
      }
    }
  }

  & .region-content {
    margin-top: 240px;
  }

  & .region-footer-pre {
    padding-top: 5em;
  }

  & .region-content-related {
    padding-top: 5em;

    & + .region-footer-pre {
      padding-top: 0;
    }
  }
}
