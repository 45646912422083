.menu,
.menu__item {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu__item {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2;

  & .menu__item {
    font-size: 1.6rem;
    font-weight: normal;
  }
}

.menu {
  & > .menu__item {
    float: left;
    padding: 0 20px;
  }

  & .menu {
    & > .menu__item {
      float: none;
      padding: 0;
    }
  }
}

.menu__link {
  @extend .layer;
  display: block;

  &::after {
    content: '';
    border-bottom: 3px solid var(--secondary);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: 0.5em;
    display: none;
  }

  &:hover,
  &:focus,
  &.is-active-trail {
    &::after {
      display: block;
    }
  }
}

.menu-position-link {
  @extend .hidden;
}
