.view-person-contact {
  & a {
    @extend .icon;
    margin-left: 2px;
    margin-top: 6px;
    cursor: pointer;
    font-size: 0;

    &::before {
      font-size: 2.4rem;
    }
  }

  & .views-field-field-webtree-phone {
    & a {
      color: var(--tertiary);
      @extend .icon-phone;
    }
  }

  & .views-field-field-webtree-email {
    & a {
      color: var(--primary);
      @extend .icon-contact;
    }
  }
}
