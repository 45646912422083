.layout-width-full {
  width: 100% !important;
}

.layout-height-full {
  height: 100% !important;
}

.layout-height-screen {
  min-height: 640px;
  max-height: 2000px;
  height: 100%;
  height: 100vh;
}

.layout-center-x {
  transform: translateX(-50%);
}

.layout-center-y {
  transform: translateY(-50%);
}

.layout-center {
  transform: translate(-50%);
}

.layout-top {
  top: 0;
}

.layout-right {
  right: 0;
}

.layout-bottom {
  bottom: 0;
}

.layout-left {
  left: 0;
}

.layout-cover {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.layout-16x9 {
  padding-top: 56.25%;
}
