:root {
  --primary: #EA7125;
  --secondary: #FECB00;
  --tertiary: #44697D;

  --black: #474747;
  --white: #FFF;
  --grey: #9EA7AD;
  --greyLight: #F2F2F2;

  --status: #1B5E20;
  --statusAlt: #C8E6C9;

  --warning: #FF6F00;
  --warningAlt: #FFECB3;

  --error: #B71C1C;
  --errorAlt: #FFCDD2;

  --font-family-heading: 'Stone', sans-serif;
  --font-family-content: 'Trebuchet MS', Trebuchet, sans-serif;
  --font-family-touch: 'Philing', sans-serif;
  --font-family-mono: 'Lucida Console', Monaco, monospace;
  --font-family-icon: 'Icon';

  --font-size-primary: 2.1rem;
  --font-size-secondary: 1.8rem;

  --line-height-primary: 1.75;
  --line-height-secondary: 1.33;

  --gutter-fluid: 30px;
  --gutter-fluid-md: 60px;
  --gutter-fluid-lg: 90px;
  --gutter: 30px;
  --gutter-md: 20px;
  --gutter-sm: 15px;

  /* --menu-nav-padding: 32px; */
  /* --menu-subnav-padding: 24px; */

  /* --header-height: 88px; */
  /* --header-height-md: 100px; */
  /* --header-height-lg: 120px; */
  /* --header-height-xl: 132px; */
}
