.view-news-list {
  & .views-row-announcement {
    & .views-field-title,
    & .views-field-nothing {
      @extend .hidden;
    }
  }

  & .views-field-field-webtree-primary-image {
    margin-bottom: 2em;

    & img {
      @extend .img-fit-x;
    }
  }

  & .views-field-field-webtree-person {
    @extend .view-list .view-list-right;
  }

  & .views-field-nothing {
    & a {
      @extend %btn;
      @extend .link;
    }
  }
}
