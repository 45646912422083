.region-content-pre-wrapper {
  background-color: var(--secondary);
  background-image: url(../../../theme/img/splash-content-pre.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;

  @media (--screen-sm) {
    min-height: 25%;
    min-height: 25vh;
  }

  @media (--screen-md) {
    min-height: 40%;
    min-height: 40vh;
  }
}
