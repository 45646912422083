%btn {
  appearance: none;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1.6rem;
  border: none;
  transition: background-color 0.2s, color 0.2s;
  line-height: 1.5;
}

%btn-primary {
  color: var(--white);
  background-color: var(--primary);
  padding: 18px 68px 16px 68px;
  line-height: 1;
  box-shadow: none !important;

  &:hover,
  &:focus {
    background-color: var(--secondary);
  }
}

%btn-secondary {
  border: 1px solid var(--white);
  padding: 18px 76px 16px 76px;
  line-height: 1;
  color: var(--primary);
  border: 1px solid var(--primary);

  &:hover,
  &:focus {
    color: var(--white);
    background-color: var(--primary);
  }
}

%btn-sm {
  padding: 9px 16px 7px 16px;
}
