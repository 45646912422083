.view-events-details {
  & .views-label {
    & + .field-content {
      display: inline;
    }
  }

  & .views-field-title {
    margin-top: 1em;

    & a {
      @extend %btn;
      @extend %btn-primary;
      color: var(--white);
    }
  }
}
