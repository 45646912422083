.block-views-sponsors-footer-block {
  & .block-title {
    font-family: var(--font-family-content);
    font-size: 1.6rem;
    font-style: italic;
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 12px;
  }
}

.view-sponsors-footer {
  & .views-row {
    display: inline-block;
    margin-right: 4px;
    margin-left: 4px;
  }

  & .views-row-first {
    margin-left: 0;
  }

  & .views-row-last {
    margin-right: 0;
  }

  & a {
    display: block;
    height: 36px;
  }

  & img {
    @extend .img-fit-y;
  }
}
