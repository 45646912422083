.view-content-contact {
  & .views-row {
    margin-top: 5em;
  }

  & .views-row-first {
    margin-top: 3rem;
  }

  & .views-field-title {
    @extend h6;

    & a {
      @extend .link-hover;
    }
  }

  & .views-field-field-webtree-position {
    font-style: italic;
    width: 40%;
    margin: 0 auto;
    margin-top: -1em;
    margin-bottom: 1.5em;
    line-height: var(--line-height-secondary);
  }

  & .views-field-field-webtree-phone {
    & a {
      @extend .link-hover;
    }
  }
}
