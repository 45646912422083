.view-events-dual-column-list {
  & .view-content {
    & > .view-grouping {
      @extend .col-xs-12;
      @extend .col-sm-6;
      display: none;
      padding-top: 0;
      margin-top: 0;
      border-top: none;

      &:first-child,
      &:nth-child(2) {
        display: block;
      }
    }
  }

  & .views-row {
    @extend .col-xs-12;
    border-top: 2px solid var(--greyLight);
    padding-top: 20px;
    padding-bottom: 20px;

    &.views-row-first {
      border-top: none;
      padding-top: 0;
    }

    &.views-row-last {
      padding-bottom: 64px;
    }
  }

  & .views-field {
    @extend .pull-left;
    line-height: var(--line-height-secondary);
  }

  & .views-field-field-webtree-calendar-date-1 {
    width: 144px;
  }

  & .views-field-title {
    width: calc(100% - 144px);

    & a {
      @extend .link;
      @extend .link-hover;
    }
  }
}
