.field-name-body,
.field-name-field-description {
  margin-bottom: 5em;

  * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & p,
  & ul,
  & ol,
  & h2,
  & h3,
  & blockquote {
    margin-top: 1em;
    margin-bottom: 3em;
  }

  & li {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    font-size: var(--font-size-secondary);
  }

  & h2 {
    margin-top: 5em;
  }

  & a {
    @extend .link-inline;
  }

  & blockquote {
    margin-left: 0;
    margin-right: 0;
    padding-left: 30px;
    border-left: 3px solid var(--secondary);

    & cite {
      display: inline-block;
      font-size: var(--font-size-secondary);
      max-width: 75%;
      text-align: right;
      float: right;
      transform: translateY(-2em);
      padding-bottom: 2em;
    }
  }

  & cite {
    &::before {
      content: '\2014';
      margin-right: 8px;
    }
  }
}
