.pager-mini {
  @extend .layer;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 5em;
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: var(--font-size-secondary);

  & a {
    @extend .link;
    @extend .link-hover;
  }

  & .pager-nav-item {
    @extend .layer-absolute;
    top: 0;
  }

  & .pager-previous {
    left: 0;
  }

  & .pager-next {
    right: 0;
  }

  & .pager-current-part {
    font-style: italic;
  }

  & .pager-current-page,
  & .pager-current-max {
    font-style: normal;
  }

  & .pager-disabled {
    color: var(--greyLight);
  }
}
