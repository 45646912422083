.region-header {
  display: inline-block;
  padding-left: 0;
  vertical-align: top;

  @media (--screen-xs-only) {
    width: calc(50% - 57px);
    padding-right: 0;
  }
}
