.icon {
  &::before {
    font-family: var(--font-family-icon);
  }
}

.icon-only {
  font-size: 0;
  color: transparent;

  &::before {
    font-size: 3.1rem;
    line-height: 1;
  }
}

.icon-bars {
  &::before { content: '\f108'; }
}

.icon-calendar {
  &::before { content: '\f107'; }
}

.icon-contact {
  &::before { content: '\f106'; }
}

.icon-facebook {
  &::before { content: '\f10b'; }
}

.icon-google-plus {
  &::before { content: '\f109'; }
}

.icon-location {
  &::before { content: '\f106'; }
}

.icon-multiply {
  &::before { content: '\f105'; }
}

.icon-next {
  &::before { content: '\f104'; }
}

.icon-phone {
  &::before { content: '\f100'; }
}

.icon-previous {
  &::before { content: '\f103'; }
}

.icon-rss {
  &::before { content: '\f102'; }
}

.icon-search {
  &::before { content: '\f101'; }
}

.icon-twitter {
  &::before { content: '\f10a'; }
}

.feed-icon {
  @extend .icon;
  @extend .icon-rss;
  color: var(--primary);
  height: 24px;
  width: 24px;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  vertical-align: middle;

  & img {
    @extend .hidden;
  }

  &::before {
    font-size: 2.4rem;
    color: inherit;
  }
}

.ical-icon {
  @extend .icon;
  @extend .icon-calendar;
  color: var(--white);
  background-color: var(--tertiary);
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  vertical-align: middle;

  &::before {
    font-size: 1.2rem;
    color: inherit;
    display: inline-block;
    line-height: 24px;
    margin-top: -3px;
    vertical-align: middle;
  }

  & img {
    @extend .hidden;
  }
}
