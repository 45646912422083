.view {
  & .more-link {
    @extend .layout-width-full;
    text-align: center;

    & a {
      @extend %btn;
      @extend .link;
    }
  }
}
