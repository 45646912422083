.breadcrumb {
  font-size: 1.4rem;
  line-height: 1.75;
  text-align: center;
  height: 0px;
  position: relative;

  & ol {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  & li {
    display: inline;
  }

  & a {
    @extend .link;
    @extend .link-hover;
    padding-bottom: 2px;
  }
}
