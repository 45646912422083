.layer {
  position: relative;
}

.layer-absolute {
  position: absolute;
}

.layer-absolute-sm {
  @media (--screen-sm) {
    position: absolute;
  }
}

.layer-absolute-md {
  @media (--screen-md) {
    position: absolute;
  }
}

.layer-fixed {
  position: fixed;
  z-index: 1000;
}

.layer-above {
  z-index: 100;
}

.layer-below {
  z-index: 1;
}
