.block-disqus-disqus-comments {
  @extend .container-content;
  @extend .container-fluid;
  @extend .block-secondary;
  padding-bottom: 5em;

  & .block-content {
    @extend .block-secondary .block-title;
  }
}
