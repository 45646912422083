.ajax-progress {
  @extend .layer-fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background: color(var(--white) alpha(50%));

  & .throbber {
    @extend .layer-absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    background-image: url(../../../theme/img/loader.gif);
  }
}
