html {
  box-sizing: border-box;
  font-size: 10px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: var(--font-family-content);
  color: var(--black);
  font-size: var(--font-size-primary);
  line-height: var(--line-height-primary);

  /* &::after { */
    /* content: 'mobile'; */
    /* position: fixed; */
    /* color: red; */
    /* font-weight: bold; */
    /* background: white; */
    /* padding: 2px 6px; */
    /* line-height: 1; */
    /* bottom: 0; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    /* z-index: 10000; */
    /* font-size: 1.2rem; */

    /* @media (--screen-sm) { */
      /* content: 'screen-sm'; */
    /* } */

    /* @media (--screen-md) { */
      /* content: 'screen-md'; */
    /* } */

    /* @media (--screen-lg) { */
      /* content: 'screen-lg'; */
    /* } */

    /* @media (--screen-xl) { */
      /* content: 'screen-xl'; */
    /* } */
  /* } */
}

main {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  display: block;
  margin: 0;
  line-height: 1;
  font-weight: bold;
}

h1 {
  font-family: var(--font-family-heading);
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em;

  @media (--screen-md) {
    margin-top: 104px;
    margin-bottom: 104px;
  }
}

h2 {
  font-family: var(--font-family-heading);
  font-size: 2.1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

h3, h4, h5 {
  /* TODO - Find better Stone web font */
  font-family: var(--font-family-heading);
  font-size: 2.1rem;
  letter-spacing: 0.033em;
}

h6 {
  font-family: var(--font-family-content);
  font-size: 1.8rem;
  margin-bottom: 32px;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  @extend %btn;
  @extend %btn-primary;

  &[disabled] {
    opacity: 0.33 !important;
  }
}

pre {
  font-size: 1.2rem;
  line-height: 1.2;
}

address {
  font-style: normal;
  font-size: 1.6rem;
}

select {
  opacity: 0;
  width: auto;
  font-size: var(--font-size-secondary);
  padding: 0 26px 0 12px;
}

input[type="text"],
input[type="email"],
textarea {
  font-size: var(--font-size-secondary);
  border: none;
  background: var(--white);
  color: var(--black);
  padding: 10px 12px;
  outline: none !important;
  border: 0 solid var(--grey);

  &:focus,
  &:active {
    border-color: var(--black);
  }

  &.error {
    border-color: var(--primary);
    outline: 1px solid var(--primary);
  }
}

input[type="text"],
input[type="email"],
input[type="date"],
input[type="number"] {
  width: 100%;
  max-width: 420px;
  height: 42px;
  line-height: 1;
  border-bottom-width: 1px;
  vertical-align: middle;
}

textarea {
  width: 100%;
  line-height: 1.5;
  border-width: 1px;
  resize: none;
}

var {
  display: none;
}

legend {
  @extend h2;
  margin-bottom: 2em;
}

fieldset {
  border: none;
  margin-top: 5em;
  margin-bottom: 5em;
}

label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 0.5em;
  margin-right: 0;
  min-width: 200px;
  vertical-align: middle;
  font-size: var(--font-size-secondary);
}

input[type="checkbox"],
input[type="radio"] {
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  appearance: none;
  visibility: hidden;

  & + label {
    font-weight: normal;
    margin: 0;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 22px;
      vertical-align: middle;
      text-align: center;
      margin-right: 0.5em;
      border: 1px solid var(--grey);
      font-size: var(--font-size-primary);
      font-family: var(--font-family-mono);
      color: var(--white);
      text-indent: 1px;
      position: relative;
      top: -1px;
    }
  }

  &:focus,
  &:active {
    & + label {
      &::before {
        border-color: var(--black);
      }
    }
  }

  &.error {
    & + label {
      &::before {
        border-color: var(--primary);
        outline: 1px solid var(--primary);
      }
    }
  }

  &:checked {
    & + label {
      &::before {
        content: '\2713';
        background-color: var(--grey);
      }
    }

    &:focus,
    &:active {
      & + label {
        &::before {
          background-color: var(--black);
        }
      }
    }
  }
}

input[type="radio"] {
  & + label {
    &::before {
      border-radius: 12px;
    }
  }
}

::placeholder {
  font-size: inherit;
  color: color(var(--black) alpha(50%));
  font-style: italic;
}

::selection {
  color: var(--black);
  background-color: var(--secondary);
}
