.view-donate-button {
  transform: translateY(0);
  transition: transform 0.2s;

  @media (--screen-sm) {
    transform: translateY(68px);
  }

  & .views-field-link-title {
    & a {
      @extend %btn;
      @extend %btn-primary;
      padding-left: 24px;
      padding-right: 24px;
      transition: none;

      @media (--screen-xs-only) {
        height: 72px;
        width: 100%;
        line-height: 72px;
        padding: 0;
        text-align: center;
      }
    }
  }
}
