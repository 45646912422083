.view-section-children {
  @extend .clearfix;
  font-size: var(--font-size-secondary);

  & .views-row {
    @extend .col-xs-12;
    @extend .col-md-6;
    @extend .layer;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5em;
  }

  & .views-row-odd {
    clear: left;
  }

  & .views-field {
    @extend .layer;
    @extend .layer-above;
    background: var(--white);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 45px;
    padding-right: 45px;

    @media (--screen-sm) {
      width: 75%;
    }

    @media (--screen-xl) {
      width: 56.25%;
    }
  }

  & .views-field-field-webtree-primary-image {
    @extend .layer-below;
    @extend .layout-width-full;
    padding-left: 0;
    padding-right: 0;

    & .field-content {
      padding-top: 44.125%;

      &:empty {
        &::before {
          @extend .layer-absolute;
          @extend .img-placeholder;
          content: '';
          top: 0;
          width: 100%;
          padding-top: 56.25%;
        }
      }
    }

    & img {
      @extend .layer-absolute;
      @extend .img-fit-x;
      top: 0;
    }
  }

  & .views-field-title {
    @extend h2;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    & a {
      @extend .link;
      @extend .link-hover;
    }
  }

  & .views-field-nothing {
    padding-top: 2em;
    text-align: center;

    & a {
      @extend %btn;
      @extend .link;
    }
  }
}
