.block-sitewide-social-block {
  letter-spacing: 0.2em;

  & .sitewide-social-link {
    & a {
      @extend .icon;
      transition: color 0.1s;

      &::before {
        font-size: 3.6rem;
      }

      &:hover,
      &:focus {
        color: var(--secondary);
      }
    }

    & span {
      @extend .hidden;
    }
  }

  & .social-link-contact {
    & a {
      @extend .icon-contact;
    }
  }

  & .social-link-facebook {
    & a {
      @extend .icon-facebook;
    }
  }

  & .social-link-twitter {
    & a {
      @extend .icon-twitter;
    }
  }
}
