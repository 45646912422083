/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; -webkit-transform: translate3d(0,0,0); overflow:visible !important;}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background: var(--white); opacity: 0.85;}
#colorbox{outline:0;}
    #cboxTopLeft{width:0; height:0; background: transparent;}
    #cboxTopRight{width:0; height:0; background: transparent;}
    #cboxBottomLeft{width:0; height:0; background: transparent;}
    #cboxBottomRight{width:0; height:0; background: transparent;}
    #cboxMiddleLeft{width:0; background: transparent;}
    #cboxMiddleRight{width:0; background: transparent;}
    #cboxTopCenter{height:0; background: transparent;}
    #cboxBottomCenter{height:0; background: transparent;}
    #cboxContent{background: var(--primary);}
        .cboxIframe{background:var(--primary);}
        #cboxError{padding:50px;}
        #cboxLoadedContent{margin-bottom: 0;}
        #cboxTitle{padding: 20px 30px; position:absolute; bottom:30px; right:30px; text-align:left; max-width: 40%; background: color(var(--white) alpha(90%));}
        #cboxTitle:empty{display: none !important;}
        #cboxTitle table{float: right; width: 100%; font-size: var(--font-size-primary); line-height: 1;}
        #cboxCurrent{display: none !important;}
        #cboxLoadingOverlay{background: transparent;}
        #cboxLoadingGraphic{background: url(../../../theme/img/loader.gif) no-repeat 50% 50%;}

        /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
        #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }

        /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
        #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

        #cboxSlideshow{position:absolute; bottom:0; right:30px; color:#0092ef;}
        #cboxPrevious{outline: none; vertical-align: middle; position: absolute; top: 50%; left: 20px; @extend .icon; @extend .icon-only; @extend .icon-previous; }
        #cboxPrevious::before {
          color: var(--white);
        }
        #cboxPrevious:hover{
          &::before {
            color: var(--secondary);
          }
        }
        #cboxNext{outline: none; vertical-align: middle; position: absolute; top: 50%; right: 20px; @extend .icon; @extend .icon-only; @extend .icon-next; }
        #cboxNext::before {
          color: var(--white);
        }
        #cboxNext:hover{
          &::before {
            color: var(--secondary);
          }
        }
        #cboxClose{outline: none; vertical-align: middle; position: absolute; top: 20px; right: 20px; @extend .icon; @extend .icon-only; @extend .icon-multiply; }
        #cboxClose::before {
          color: var(--white);
        }
        #cboxClose:hover{
          &::before {
            color: var(--secondary);
          }
        }

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
}
