.block-feature {
  @extend .col-md-6;

  color: var(--white);
  background-color: var(--primary);
  padding-top: 52px;
  padding-bottom: 52px;
  text-align: center;

  & .block-title {
    @extend .row;
    padding-top: 28px;
    padding-bottom: 64px;
    font-family: var(--font-family-touch);
    font-size: 4.5rem;
    font-weight: normal;
    text-transform: none;
  }

  & + .block-feature {
    &::before {
      @extend .layer-absolute;
      @extend .layer-above;
      content: '';
      background: var(--white);

      width: auto;
      height: 1px;
      top: 0;
      left: var(--gutter-fluid);
      right: var(--gutter-fluid);

      @media (--screen-md) {
        width: 1px;
        height: auto;
        top: 52px;
        right: auto;
        bottom: 52px;
        left: 0;
      }
    }
  }

  & .webform-client-form {
    margin-top: 0;
    margin-bottom: 0;
  }

  & .form-actions {
    padding-top: 52px;
    padding-bottom: 32px;
  }

  & .webform-submit {
    @extend %btn-secondary;
    border-color: var(--white);
    color: var(--white);

    &:hover,
    &:focus {
      background-color: var(--white);
      color: var(--primary);
    }
  }

  & input {
    text-align: center;

    &[type="text"],
    &[type="email"] {
      border: none !important;
      max-width: 380px;
      height: 45px;
    }
  }

  & select {
    width: 100%;
    max-width: 380px;
  }

  & .select2-container {
    & .select2-choice,
    & .select2-drop-above .select2-choice {
      font-size: var(--font-size-primary);
    }
  }

  & .form-item {
    margin: 0;
  }

  & .webform-component-select-or-other {
    @extend .clearfix;
    @extend .layer;
    @extend .layer-below;
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
  }

  & .form-type-textfield {
    @extend .layer-absolute;
    @extend .layer-above;
    width: 20% !important;
    padding: 0 !important;
    margin: 0 !important;
    opacity: 1 !important;
    height: auto !important;
    transition: none !important;
    right: 0;

    &::before {
      @extend .layer-absolute;
      @extend .layer-above;
      top: 0;
      left: 2px;
      content: '$';
      line-height: 45px;
      color: var(--black);
    }

    & input {
      padding-left: 16px;
    }
  }

  & .form-type-radios {
    & label {
      @extend .pull-left;
      width: 20%;
      letter-spacing: 1px;

      min-width: 0;
      border: 1px solid var(--white);
      border-left: none;
      font-size: 1.6rem;
      line-height: 43px;
      text-transform: uppercase;

      &::before {
        content: '$' !important;
        border: none;
        display: inline;
        background: none !important;
        margin: 0;
        font: inherit;
        line-height: inherit;
        vertical-align: top;
        top: 0;
        color: inherit;
      }
    }

    & .form-item {
      &:first-child {
        & label {
          border-left: 1px solid var(--white);
        }
      }

      &:last-child {
        & label {
          &::before {
            content: none !important;
          }
        }
      }
    }

    & input[type="radio"] {
      &:checked {
        & + label {
          background-color: var(--white);
          color: var(--black);
          font-size: var(--font-size-primary);
        }
      }
    }
  }
}
