.view-facts-page {
  & .views-row {
    @extend .clearfix;
    margin-bottom: 3em;
    margin-top: 3em;
    border-top: 1px solid var(--greyLight);
    padding-top: 3em;
    font-style: italic;
    line-height: var(--line-height-secondary);
  }

  & .views-field-body {
    margin-bottom: 12px;
  }

  & .views-field-field-webtree-link {
    @extend .pull-right;
    display: inline-block;
    font-size: var(--font-size-secondary);
    max-width: 75%;
    text-align: right;

    & .field-content {
      &::before {
        content: '\2014';
        margin-right: 8px;
      }
    }

    & a {
      display: inline-block;
      @extend .link;
    }
  }
}
