.select2-container {
  color: var(--black);

  & * {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
  }

  & .select2-display-none {
    @extend .hidden;
  }

  & .select2-offscreen {
    min-width: 0;
  }

  & .select2-choice {
    border-bottom: 1px solid var(--grey) !important;
    font-size: var(--font-size-secondary);
    height: 36px;
    line-height: 36px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &.error {
    & .select2-choice {
      border-color: var(--primary) !important;
    }
  }

  & .select2-choice,
  & .select2-drop-above .select2-choice {
    height: auto;
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
    padding-top: 9px;
    padding-left: 12px;
    padding-bottom: 9px;
    background-color: var(--white);
    background-image: none !important;
  }

  & .select2-choice .select2-arrow {
    background: none;
  }

  & .select2-choice .select2-arrow b {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -3px;
    background: none;
    border-top: 6px solid var(--black) !important;
    border-left: 4px solid transparent !important;
    border-right: 4px solid transparent !important;
    width: 0;
    height: 0;
  }

  &.select2-dropdown-open .select2-arrow b {
    border-top: none !important;
    border-bottom: 6px solid var(--black) !important;
  }
}

.select2-drop {
  color: var(--black);
  background: var(--greyLight);
  border: none !important;
  border-top: 1px solid var(--grey) !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  & .select2-results {
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  & .select2-results .select2-highlighted {
    background: var(--secondary);
  }

  & .select2-results .select2-result-label {
    font-size: var(--font-size-secondary);
    padding: 0 10px;
  }

  &.select2-drop-above {
    border-top: none !important;
    border-bottom: 1px solid var(--grey) !important;
  }
}

.webform-container-inline {
  & .select2-container {
    display: inline-block;
  }

  & .select2-display-none {
    display: none;
  }
}
