.scrolling {
  & .header-logo__link {
    @media (--screen-sm) {
      transform: translateY(24px);
    }
  }

  & .header-logo__slogan {
    height: 0;
    opacity: 0;
  }

  & .view-donate-button {
    @media (--screen-sm) {
      transform: translateY(35px);
    }
  }

  & .region-navigation {
    @media (--screen-sm) {
      min-height: 122px;
    }

    &::before {
      transform: translateY(0);

      @media (--screen-sm) {
        transform: translateY(26px);
      }
    }

    & .menu-block-wrapper {
      & > .menu {
        & > .menu__item {
          & > .menu__link {
            @media (--screen-md) {
              padding-top: 40px;
              padding-bottom: 40px;

              &::after {
                top: 61px;
              }
            }
          }
        }
      }
    }

    & .menu {
      & .menu {
        @media (--screen-md) {
          margin-top: -20px;
        }
      }
    }
  }
}
