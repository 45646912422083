.block-views-homepage-blurbs-block {
  color: var(--white);
  background-color: var(--primary);
  overflow: hidden;

  & .block-content {
    @media (--screen-xs-only) {
      padding-bottom: 0;
    }
  }
}
