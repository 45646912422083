.addtocalendar {
  @extend .layer;

  &:hover {
    background-color: var(--secondary);

    & .atcb-list {
      visibility: visible;
    }
  }

  & .atcb-list {
    @extend .layer-absolute;
    @extend .layer-above;
    font-size: 1.6rem;
    visibility: hidden;
    top: 100%;
    left: 0;
    width: 170px;
    margin: 0;
    padding: 0;
    background-color: var(--greyLight);
  }

  & .atcb-item {
    list-style: none;
    cursor: pointer;
  }

  & .atcb-link {
    cursor: pointer;

    &:focus,
    &:active {
      & ~ .atcb-list {
        visibility: visible;
      }
    }
  }

  & .atcb-item-link {
    display: block !important;
    padding: 0 10px;
  }
}
