.view-homepage-blurbs {
  @extend .container-fluid;
  margin-left: -22px;
  margin-right: -22px;

  & .view-content {
    position: relative;

    @media (--screen-sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  & .views-row {
    margin-right: 22px;
    margin-bottom: 42px;
    margin-left: 22px;
    padding-bottom: 42px;
    border-bottom: 1px solid var(--white);
    text-align: center;
    position: relative;

    &.views-row-last {
      border-bottom: none;
    }

    @media (--screen-sm) {
      max-width: 33.33%;
      margin: 0;
      padding-right: 22px;
      padding-bottom: 84px;
      padding-left: 22px;
      border-bottom: none;
    }

    @media (--screen-md) {
      max-width: 20%;
    }
  }

  & .views-row-4,
  & .views-row-5 {
    @media (--screen-sm) {
      padding-top: 84px;
    }

    @media (--screen-md) {
      padding-top: 0;
    }
  }

  & .views-field-field-webtree-thumbnail {
    font-size: 0;
    /* float: left; */
    /* margin-right: var(--gutter-fluid); */

    @media (--screen-sm) {
      /* float: none; */
      /* margin-right: 0; */
    }

    & img {
      @extend .img-fit-x;
      width: 80px;
      opacity: 0.6;
      margin: 0 auto;

      @media (--screen-sm) {
        width: 120px;
      }
    }
  }

  & .views-field-field-display-title {
    @extend h3;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 64px;
    padding-top: 12px;

    @media (--screen-sm) {
      height: 80px;
    }

    & .field-content {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
  }

  & .views-field-field-description {
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
    opacity: 0.95;
    text-align: left;
    padding-top: 12px;
  }

  & .views-field-field-webtree-link {
    @extend .layer-absolute-sm;
    padding-top: 32px;

    @media (--screen-sm) {
      padding-top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    & a {
      @extend %btn;
      @extend .link;
    }
  }
}
