.view-event-details {
  line-height: var(--line-height-secondary);
  margin-top: -2em;
  margin-bottom: 5em;

  & .views-field {
    margin-top: 2em;

    &:first-child {
      margin-top: 0;
    }
  }

  & .views-label {
    @extend label;
  }

  & .views-field-field-webtree-address {
    & .field-content {
      & span {
        font-size: var(--font-size-secondary);
        white-space: nowrap;
      }
    }

    & a {
      @extend .link;
      display: inline-block;
    }
  }

  & .views-field-title-1 {
    margin-top: 3em;

    & a {
      @extend %btn;
      @extend %btn-primary;
      color: var(--white);
    }
  }
}
