.search-results {
  padding-left: 0;
  margin-bottom: 5em;

  & .search-result {
    margin-bottom: 3em;
    padding-top: 3em;
    margin-top: 3em;
    border-top: 2px solid var(--greyLight);
  }

  & .search-result-title {
    margin-bottom: 1em;
    line-height: var(--line-height-secondary);
  }

  & p {
    font-size: var(--font-size-secondary);
  }
}
