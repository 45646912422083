.view-announcements-slideshow {
  & .view-content {
    @extend .layer;
  }

  & .feed-icon {
    @extend .layer-absolute;
    top: 82px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 132px;
    color: var(--secondary);
  }

  & .views-slideshow-cycle-main-frame,
  & .views-slideshow-cycle-main-frame-row {
    @extend .layout-width-full;
  }

  & .views-slideshow-controls-text {
    @extend .layer-absolute;
    @extend .layer-above;
    top: 50%;
    left: var(--gutter-sm);
    right: var(--gutter-sm);

    @media (--screen-sm) {
      left: 10%;
      right: 10%;
    }
  }

  & .views-slideshow-controls-text-previous,
  & .views-slideshow-controls-text-next {
    @extend .layer-absolute;
    @extend .layout-center-y;

    & a {
      @extend .layer;
      @extend .icon;
      font-size: 0;

      &::before {
        @extend .layer-absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 5.7rem;
      }

      &:hover {
        color: var(--secondary);
      }
    }
  }

  & .views-slideshow-controls-text-previous {
    left: 0;

    & a {
      @extend .icon-previous;

      &::before {
        left: 0;
      }
    }
  }

  & .views-slideshow-controls-text-next {
    right: 0;

    & a {
      @extend .icon-next;

      &::before {
        right: 0;
      }
    }
  }

  & .views-slideshow-controls-text-pause {
    @extend .hidden;
  }

  & .views-field-body {
    display: table;
    table-layout: fixed;
    width: 56.25%;
    margin: 0 auto;

    & .field-content {
      display: table-cell;
      vertical-align: middle;

      & a {
        @extend .link;
        display: inline-block;
        line-height: var(--line-height-secondary);
      }
    }
  }
}
