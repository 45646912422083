.view-content-in-this-section {
  @media (--screen-md) {
    width: 80%;
    margin: 0 auto;
  }

  & .views-row {
    display: inline-block;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 0;
    margin-bottom: 2em;

    @media (--screen-md) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  & .views-field-title {
    display: inline;
  }
}
