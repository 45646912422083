.block-menu-menu-footer {
  color: var(--white);

  & .block-title {
    @extend .hidden;
  }

  & a {
    font-size: 1.2rem;
    opacity: 0.5;
    line-height: 1.5;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  & .menu__item {
    padding-left: 0;
    padding-right: 0;
    font-weight: normal;
  }
}
