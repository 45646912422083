.region-footer-post {
  color: var(--white);
  background-color: var(--black);
  font-size: 0;
  padding-top: 29px;
  padding-bottom: 29px;

  @media (--screen-sm) {
    padding-top: 38px;
    padding-bottom: 38px;
  }

  & .block {
    font-size: 1.2rem;
    line-height: 1.5;

    @media (--screen-sm) {
      display: inline-block;

      &::before {
        content: '|';
        padding-left: 10px;
        padding-right: 10px;
      }

      &:first-child {
        &::before {
          content: none;
        }
      }
    }
  }

  & .block-title {
    @extend .hidden;
  }

  & .block-content {
    display: inline-block;

    & a {
      @extend .link;
      display: inline-block;
    }
  }
}
