.img-fit-screen-x {
  max-height: 100%;
}

.img-fit-x {
  display: block;
  width: 100%;
  height: auto;
}

.img-fit-y {
  display: block;
  width: auto;
  height: 100%;
}

.img-cover {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: var(--black);
}

.img-placeholder {
  background-color: var(--secondary);
  background-image: url(../../../theme/img/splash-content-pre.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}
