.link {
  box-shadow: inset 0 -0.2em 0 var(--secondary);
  transition: background 0.2s;
  text-decoration: none !important;

  &:hover,
  &:focus {
    background-color: var(--secondary);
  }
}

.link-inline {
  border-bottom: 2px solid var(--secondary);
  box-shadow: inset 0 -1px 0 var(--secondary);
  transition: background 0.2s;
  text-decoration: none !important;

  &:hover,
  &:focus {
    background-color: var(--secondary);
  }
}

.link-hover {
  box-shadow: none;
  border-bottom: none;
}

.link-hint {
  box-shadow: none;

  &:hover,
  &:focus {
    box-shadow: inset 0 -0.2em 0 var(--secondary);
  }
}

.link-disabled {
  box-shadow: none !important;
  background: none !important;
  border: none !important;
}
