.field-name-field-webtree-gallery-image {
  max-width: calc(100% + 60px);
  width: 840px;
  position: relative;
  overflow: hidden;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 5em;

  & a {
    @extend .link-disabled;
    @extend .col-xs-4;
    padding-left: 0;
    padding-right: 0;
  }

  & img {
    @extend .img-fit-x;
  }
}

