.region-footer-left {
  display: none;

  @media (--screen-md) {
    display: block;
  }

  & .menu-level-1 {
    & > .menu {
      & > .menu__item {
        width: 20%;
        padding-left: 0;
        padding-right: 0;
        line-height: 1;

        @media (--screen-md) {
          width: 12.5%;
        }

        & > .menu__link {
          @extend h6;
          display: inline-block;
        }
      }
    }
  }

  & .menu__link {
    display: inline-block;
  }
}
