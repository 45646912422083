.view-sponsors-list {
  & .view-content h3 {
    @extend h2;
    text-align: center;
    padding-bottom: 36px;
    margin-top: 3em;
  }

  & .views-field-title {
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  & .views-field-field-webtree-website {
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);

    & a {
      @extend .link;
      display: inline-block;
    }
  }

  & .views-field-body {
    margin-top: 2em;
  }

  /*
  &.view-display-id-top {
    & .views-field-field-webtree-thumbnail {
      @extend .pull-right;
      padding-left: 30px;
    }

    & .views-row {
      margin-bottom: 5em;
    }
  }
  */

  &.view-display-id-top {
    & .view-content h3:first-child {
      margin-top: 0;
    }
  }

  &.view-display-id-top,
  &.view-display-id-mid,
  &.view-display-id-low {
    text-align: center;
    /* margin-bottom: 5em; */

    & * {
      display: inline-block;
    }

    & .views-row {
      display: inline-block;
      padding: 0 15px;
      margin-bottom: 1em;
    }
  }
}
