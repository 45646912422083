.view-list {
  @extend .block-byline;

  & .view-header {
    @extend .block-byline .view-header;

    &::before {
      content: 'Subscribe';
      text-transform: uppercase;
      letter-spacing: 0.1em;
      display: inline-block;
      font-size: 1.2rem;
      font-weight: normal;
      margin-right: 4px;
    }
  }

  & .views-row {
    margin-bottom: 5em;
    margin-top: 3em;
    border-top: 1px solid var(--greyLight);
    padding-top: 3em;
  }

  & .views-row-first {
    margin-top: 0;
    border-top: none;
    padding-top: 0;
  }

  & .views-field-title {
    @extend h2;
    @extend .pull-left;
    line-height: var(--line-height-secondary);
    margin-right: 2em;
    margin-bottom: 1em;

    @media (--screen-sm) {
      max-width: 61.8%;
    }

    & a {
      @extend .link;
      @extend .link-hover;
    }
  }

  & .view-list-right {
    line-height: var(--line-height-secondary);
    margin-bottom: 1em;
    clear: left;

    @media (--screen-sm) {
      clear: none;
      text-align: right;
    }
  }

  & .views-field-body {
    clear: both;
    margin-bottom: 1em;
  }

  & .view-feed-icon {
    direction: rtl;
    display: inline-block;

    & a {
      margin-left: 8px;

      &:last-child {
        margin-left: 0;
      }
    }
  }

  &.view-list-w-images,
  & .view-grouping {
    & .views-row {
      margin-top: 0;
      border-top: none;
      padding-top: 0;
    }
  }
}
