.view-grouping {
  padding-top: 3em;
  margin-top: 3em;
  border-top: 2px solid var(--greyLight);

  &:first-child {
    padding-top: 0;
    margin-top: 0;
    border-top: none;
  }
}

.view-grouping-header {
  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 3em;
}
