.field-name-field-webtree-portrait {
  @extend .col-xs-4;
  margin-top: 0.5em;
  margin-right: 32px;
  margin-bottom: 20px;

  @media (--screen-sm) {
    margin-right: 120px;
    margin-bottom: 60px;
  }

  & .field-items {
    @extend .row;
  }

  & img {
    @extend .img-fit-x;
  }
}
