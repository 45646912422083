.view-facts-random {
  text-align: center;
  background-image: url(../../../theme/img/splash-facts.jpg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 200%;

  @media (--screen-sm) {
    background-size: 100%;
  }

  @media (--screen-md) {
    background-size: inherit;
  }

  & .view-content {
    padding-top: 96px;

    @media (--screen-md) {
      padding-top: 136px;
    }
  }

  & .more-link {
    padding-bottom: 92px;

    @media (--screen-md) {
      padding-bottom: 132px;
    }
  }

  & .views-field-body {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 4px;
    line-height: var(--line-height-secondary);

    @media (--screen-sm) {
      width: 50%;
    }
  }
}
