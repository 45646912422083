.view-events-list {
  & .views-field-field-webtree-calendar-date-1 {
    @extend .view-list .view-list-right;
  }

  & .views-field-title-2 {
    clear: left;

    & a {
      @extend %btn;
      @extend .link;
    }
  }

  & .views-field-title-1 {
    display: none;
    margin-left: 1em;

    & a {
      @extend %btn;
      @extend %btn-secondary;
      @extend %btn-sm;
    }
  }

  & .views-field-field-event-fee {
    display: none;
    margin-bottom: 1em;
  }
}
