.view-news-byline {
  & .views-field-title-1 {
    &,
    & + .views-field-field-webtree-dateline {
      @media (--screen-xs-only) {
        display: block;
        transform: translateY(-5px);
        white-space: nowrap;
      }
    }
  }

  & .views-field-field-webtree-dateline {
    margin-bottom: -12px;
  }
}
