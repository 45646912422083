.messages-container {
  @extend .layer-fixed;
  right: 0;
  bottom: 0;
  left: 0;
  color: var(--white);
  text-align: center;
  max-height: 20%;
  overflow-y: auto;

  & .messages {
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
    padding: 17px 30px;

    &.status {
      background-color: var(--statusAlt);
      color: var(--status);
    }

    &.warning {
      background-color: var(--warningAlt);
      color: var(--warning);
    }

    &.error {
      background-color: var(--errorAlt);
      color: var(--error);
    }

    & .messages__list {
      margin: 0;
      padding: 0;
    }

    & a {
      text-decoration: underline;
    }

    & p,
    & li {
      margin: 0;
      list-style: none;
    }
  }
}
