.block-views-news-tiles-block {
  @extend .clearfix;

  & .block-title {
    @extend .hidden;
  }

  & + * {
    clear: left;
  }
}

.view-news-tiles {
  & .views-row {
    overflow: hidden;

    &:hover {
      & .views-field-title {
        & a {
          background-color: var(--secondary);
        }
      }
    }
  }

  & .view-content {
    & .views-row {
      @extend .col-xs-12;
      @extend .col-md-6;
    }
  }

  & .attachment {
    & .views-row {
      @extend .col-xs-12;
      @extend .col-md-4;
    }
  }

  & .views-field-field-webtree-primary-image {
    @extend .row;

    & a {
      display: block;
    }

    & img {
      @extend .img-fit-x;
    }
  }

  & .views-field-title {
    @extend .layer-absolute;
    @extend .layer-above;
    bottom: 40px;
    left: 21.875%;
    right: 21.875%;
    text-align: center;
    line-height: 1.5;

    & a {
      display: inline-block;
      padding: 4px 24px;
      background-color: color(var(--white) alpha(90%));
      transition: background-color 0.2s;
    }
  }
}
