.block-secondary {
  text-align: center;
  font-size: var(--font-size-secondary);
  line-height: 1.5;
  line-height: var(--line-height-secondary);

  & .block-content {
    padding-bottom: 3em;

    & a {
      @extend .link-inline;

      &.active {
        @extend .link-disabled;
      }
    }
  }

  & ul,
  & ol {
    padding: 0;
    margin: 0;
  }

  & li {
    list-style: none;
  }

  & .views-row {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  & .block-title {
    border-top: 2px solid var(--greyLight);
    padding-top: 64px;
    margin-bottom: 64px;

    & a {
      @extend .link-hover;
    }
  }
}
