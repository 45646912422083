.block-section {
  & .block-title {
    text-align: center;
    padding-top: 84px;
    padding-bottom: 72px;
  }

  & .block-content {
    padding-bottom: 84px;
  }
}
