@font-face {
    font-family: 'Philing';
    src: url('../fonts/philing.eot');
    src: url('../fonts/philing.eot?#iefix') format('embedded-opentype'),
         url('../fonts/philing.woff') format('woff'),
         url('../fonts/philing.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Stone';
    src: url('../fonts/stone.eot');
    src: url('../fonts/stone.eot?#iefix') format('embedded-opentype'),
         url('../fonts/stone.woff') format('woff'),
         url('../fonts/stone.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Icon';
    src: url('../fonts/icon.eot');
    src: url('../fonts/icon.eot#iefix') format('embedded-opentype'),
         url('../fonts/icon.woff') format('woff'),
         url('../fonts/icon.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
