.block-views-events-tiles-block {
  & + * {
    clear: left;
  }
}

.view-events-tiles {
  &.view-display-id-block {
    &::before {
      display: none;
      @extend .layer-absolute;
      @extend .layer-below;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      border-top: 2px solid var(--greyLight);
    }

    &::after {
      @extend .layer-absolute;
      @extend .layer-below;
      content: '';
      left: 0;
      bottom: 0;
      right: 0;
      border-bottom: 2px solid var(--greyLight);
    }

    & > .view-content {
      @extend .col-xs-12;
      @extend .col-md-7;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;

      & .views-row {
        @extend .layer;
        background-color: var(--black);
        padding-top: 0;
        /* padding-top: 100%; */

        @media (--screen-sm) {
          /* padding-top: 56.25%; */
        }
      }

      & .views-field-title,
      & .views-field-title-1,
      & .views-field-field-webtree-address,
      & .views-field-field-webtree-calendar-date,
      & .views-field-nothing-1,
      & .views-field-nothing-2,
      & .views-field-nothing-3,
      & .views-field-field-registration-form {
        @extend .hidden;
      }

      & .views-field-nothing {
        @extend .layer-absolute;
        @extend .layer-above;
        @extend h2;
        @extend .block-section .block-title;
        top: 0;
        left: 0;
        right: 0;

        @media (--screen-md-only) {
          padding-top: 64px;
          padding-bottom: 52px;
        }

        & .field-content {
          background-color: var(--white);
          padding: 10px 24px 8px 24px;
        }
      }

      & .views-field-field-webtree-primary-image {
        & img {
          @extend .img-fit-x;
        }
      }

      & .views-field-field-lat-lng {
        display: none;
        @extend .layer-absolute;
        @extend .layer-below;
        top: -8%;
        right: 0;
        bottom: 0;
        left: 0;
      }

      & .views-field-nothing-4 {
        @extend .layer-absolute;
        @extend .layer-above;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--white);
        padding: 1em 1.5em;
        line-height: 1.5;
        text-align: center;
        width: 75%;

        @media (--screen-sm) {
          max-width: 56.25%;
          width: auto;
        }

        & a {
          @extend .link;
        }

        & .date {
          margin-bottom: 0.5em;
        }

        & .title {
          line-height: var(--line-height-secondary);

          & a {
            @extend .link-hover;
            display: inline;
          }
        }

        & .links {
          & div {
            display: inline-block;
            margin-top: 1em;
          }

          & a {
            @extend %btn;
            margin: 0 1em;
          }
        }

        & .rsvp {

          & a {
            @extend %btn-primary;
            @extend %btn-sm;
          }
        }
      }
    }
  }

  & .attachment {
    @extend .col-xs-12;
    @extend .col-md-5;

    float: none;
    clear: left;

    @media (--screen-md) {
      float: left;
      clear: none;
    }

    & .view-header {
      & .block-title {
        @media (--screen-md-only) {
          padding-top: 64px;
          padding-bottom: 52px;
        }
      }
    }

    & .views-row {
      @extend .col-xs-12;
      border-top: 2px solid var(--greyLight);
      padding-top: 20px;
      padding-bottom: 20px;

      &.views-row-first {
        border-top: none;
        padding-top: 0;
      }

      &.views-row-last {
        padding-bottom: 52px;
      }
    }

    & .views-row-2 {
      @media (--screen-md-only) {
        padding-bottom: 52px;
      }
    }

    & .views-row-3 {
      @media (--screen-md-only) {
        display: none;
      }
    }

    & .views-field {
      @extend .pull-left;
      line-height: var(--line-height-secondary);
    }

    & .views-field-field-webtree-calendar-date {
      width: 144px;
    }

    & .views-field-title {
      width: calc(100% - 144px);

      & a {
        @extend .link;
        @extend .link-hover;
      }
    }
  }
}
