.block-search-form {
  padding-top: 20px;
  padding-bottom: 20px;

  & .block-content {
    @extend .layer;
  }

  & .form-text {
    background: transparent;
    border: 1px solid var(--white);
    width: 100%;
    max-width: 100%;
    height: 30px;
    font-size: 1.4rem;
    line-height: 1;
    color: var(--white);
    display: block;
    padding-right: 42px;

    @media (--screen-md) {
      width: 215px;
    }

    &:focus {
      border-color: var(--white);
    }
  }

  & .form-item {
    display: block;
    margin: 0;
  }

  & .form-actions {
    @extend .icon;
    @extend .icon-search;

    &::before {
      @extend .layer-absolute;
      @extend .layer-above;
      right: 0;
      top: 0;
      height: 30px;
      width: 30px;
      text-align: center;
      font-size: 1.6rem;
      line-height: 30px;
      color: var(--white);
      pointer-events: none;
    }
  }

  & .form-submit {
    @extend .layer-absolute;
    @extend .layer-below;
    right: 0;
    top: 0;
    height: 30px;
    width: 30px;
    padding: 0;
    text-indent: -9999px;
    background: transparent !important;
  }

  & label {
    @extend .layer-absolute;
    margin: 0;
    top: -9999px;
    left: -9999px;
  }

  ::placeholder {
    color: color(var(--white) alpha(80%));
    text-transform: lowercase;
  }
}
